function enableFees(sel)
{
	var sel_text = sel.options[sel.selectedIndex].title;
	if(sel_text == 'propay')
	{
		$('#setup_fees_box').css("display","block");
		$('#active_payment').val(sel_text);
	}
	else
	{
		$('#setup_fees_box').css("display","none");
		$('#active_payment').val('');
                //alert('test');
	}
}

function activePropay(x,id)
{
  var c = confirm("Are you sure you want to activate propay?");
  if(c)
  {
    	var y = 0;var symbol = '';
    	if(x==0)
    		{var verify_text = 'Unverified';y = 1;symbol = 'fa fa-close colorprimary'}
    	else if(x==1)
    		{var verify_text = 'Verified';y = 0;symbol = 'fa fa-check colorprimary'}

    	var myKeyVals = { status : x, client_id : id }
    	var saveData = $.ajax({
          type: 'POST',
          url: "/Admin/update_propay_verify/",
          data: myKeyVals,
          dataType: "text",
          success: function(resultData) {
    			$('#propay_verify_'+id).html('<i class="'+symbol+'"></i> '+verify_text);
    			$("#propay_verify_"+id).attr("onclick", "activePropay("+y+","+id+")");
          	//alert(resultData);
          }
    });
    saveData.error(function(resultData) { });
  } 
}


function updateErrorlog(id,x)
{
  var c = confirm("Are you sure you want to change the status?");
  if(c)
  {
      var y = 0;var symbol = '';
      if(x==0)
        {var verify_text = 'Pending';y = 1;symbol = 'fa fa-close colorprimary'}
      else if(x==1)
        {var verify_text = 'Rectified';y = 0;symbol = 'fa fa-check colorprimary'}

      var myKeyVals = { status : x, log_id : id }
      var saveData = $.ajax({
          type: 'POST',
          url: "/Admin/updateErrorlog/",
          data: myKeyVals,
          dataType: "text",
          success: function(resultData) {
          $('#errorlog_status_'+id).html('<i class="'+symbol+'"></i> '+verify_text);
          $("#errorlog_status_"+id).attr("onclick", "updateErrorlog("+id+","+y+")");
            //alert(resultData);
          }
    });
    saveData.error(function(resultData) { });
  }
}

function hidePaymentType()
{
	//	alert('test');
	$('#payment_type').css("display","none");
	$('#interval_block').css("display","none");
	$('#payment_one_time').prop("checked", true);
}

function showPaymentType()
{
	//	alert('test');
	$('#payment_type').css("display","block");
//	$('#interval_block').css("display","block");
//	$('#payment_recurring').prop("checked", true);
}
