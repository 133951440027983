function change_interval(interval_type){
  // var payment_method = $('input:radio[name=payment_method]');
  var card_payment = $('#card_payment');
  var ach_payment = $('#ach_payment');
  if(interval_type != 'recurring') {
    $('#date_due').closest('.form-group').show('fast'); // show due date on one time billing method
    $('#pay_later_block').show();
    

  }
  if(interval_type=='recurring') {
    $('#payment_type2').hide();
    $("#cash_payment_block").hide();
    $("#cheque_payment_block").hide();
    $(".hide_payment_method").show();

    if($('#card_payment').length==0){
        $('#ach_payment').prop('checked', true);
        $('#ach_content').show();
        $('#ach_list').show();
      }
    // hide ach for onetime payment and select card
    if(card_payment && card_payment.length>0) {
        // if no ach or card payment checked default it to card payment for recurring
        if(!card_payment.is(":checked") && !ach_payment.is(":checked")) {
            card_payment.prop('checked', 'checked');
        }
        paynowBlock = $("#pay_now");
        paylaterBlock = $("#pay_later");
        paynowBlock.prop('checked', 'checked');

        if(paynowBlock.is(":checked")) {
            console.log('toggle ach/card block');
            paynowBlock.trigger('change');
        } else if(paylaterBlock.is(":checked")) {
            console.log('toggle ach/card block');
            paylaterBlock.trigger('change');
        } 
        else if(card_payment.is(":checked")){
            console.log('change ach/card block');
            card_payment.trigger('change');

            var interval = '<option value="daily">Daily</option>'+
                        '<option value="weekly">Weekly</option>'+
                        '<option value="monthly">Monthly</option>'+
                        '<option value="quarterly">Quarterly</option>'+
                        '<option value="half_yearly">Half Yearly</option>' +
                        '<option value="yearly">Yearly</option>' +
                        '<option value="every_two_years">Every 2 years</option>' +
                        '<option value="every_three_years">Every 3 years</option>' +
                        '<option value="every_four_years">Every 4 years</option>' +
                        '<option value="every_five_years">Every 5 years</option>';
            $('#interval').html(interval);
        }
        else if(ach_payment.is(":checked")){
            console.log('change ach/card block');
            ach_payment.trigger('change');
        }
        // ach_payment.parent().hide();
        $("#cash_payment_block").hide();
        $("#cheque_payment_block").hide();

    }

    // hide pay later for recurring payments
    $("#pay_later_block").hide();
    $('#cheque_content').hide();

    if(card_payment.is(":checked")){

        var interval = '<option value="daily">Daily</option>'+
                    '<option value="weekly">Weekly</option>'+
                    '<option value="monthly">Monthly</option>'+
                    '<option value="quarterly">Quarterly</option>'+
                    '<option value="half_yearly">Half Yearly</option>' +
                    '<option value="yearly">Yearly</option>' +
                    '<option value="every_two_years">Every 2 years</option>' +
                    '<option value="every_three_years">Every 3 years</option>' +
                    '<option value="every_four_years">Every 4 years</option>' +
                    '<option value="every_five_years">Every 5 years</option>';
        $('#interval').html(interval);
        $('#cc_content').show();
        $('#card_list').show();


    } else  if(ach_payment && ach_payment.is(":checked")) {

        var interval = '<option value="weekly">Weekly</option>'+
                        '<option value="monthly">Monthly</option>'+
                        '<option value="quarterly">Quarterly</option>'+
                        '<option value="half_yearly">Half Yearly</option>' +
                        '<option value="yearly">Yearly</option>' +
                        '<option value="every_two_years">Every 2 years</option>' +
                        '<option value="every_three_years">Every 3 years</option>' +
                        '<option value="every_four_years">Every 4 years</option>' +
                        '<option value="every_five_years">Every 5 years</option>';
        $('#interval').html(interval);
    }

    $('#interval_block').show('fast');
    $('#authorize_now_block').hide('fast');
    $('#pay_now').prop("checked", true).trigger('click');
    $('#date_due').closest('.form-group').hide('fast'); // hide due date on recurring billing method
    //Customer dropdown in invoice modal
    /*$.ajax({
        url: js_base_url +'User/customers_dropdown',
        type: 'get',
        success:function(data) {
            $('#customer').html(data);
        },
        error:function() {
            alert("Please Try Again");
        }
    });*/

    $('#payment_type2').hide();
  }else if(interval_type=='one_time' && ach_payment && ach_payment.is(":checked")){

    console.log('ach one time');
    $('#interval_block').hide('slow');
    $('#payment_type2').hide();
     $("#cash_payment_block").show();
    $("#cheque_payment_block").show();
    $('.hide_payment_method').show()
    $('#cash_payment_block').show()
    $('#cheque_payment_block').show()
  }
  // show ach for onetime payment
  else if(interval_type=='one_time' && card_payment && card_payment.length>0) {
    // ach_payment.parent().show();
    $("#cash_payment_block").show();
    $("#cheque_payment_block").show();
    $('#interval_block').hide('slow');
    $('#authorize_now_block').show('slow');
    $('#payment_type2').show();
    $('.hide_payment_method').show()
    $('#cash_payment_block').show()
    $('#cheque_payment_block').show()
    // $("#pay_now").trigger('change');
  }  
}

    
$(document).ready(function() {

    $('#report_search').change( function(e) {
      var search = $('#report_search').val();
      if (search == '') {
        $('#custom_report').show();
      } else {
        $('#custom_report').hide();
      }
    });

// $(".js-example-basic-multiple").select2();
    //$('.datepicker').datepicker();

    /*reload page on closing modal window*/
    $("#create_invoice").on('hidden.bs.modal', function () {
        window.location.reload();
    });

    // payment type toggle for recurring payment
    $('input[name="payment_type"]:radio').change( function() {
      console.log($(this).val());
      change_interval($(this).val());
    });

    $('input[name="payment_method"]:radio').change( function() {
        change_interval($('input[name="payment_type"]:checked').val());
    });

    change_interval($('input[name="payment_type"]:checked').val());

//  payment type toggle for pay later,paynow and authorize payment
    /*$('input[name="create_mode"]:radio').change( function() {
     toggleCardBlock($(this).val());
  });
  
   function toggleCardBlock(value) {
       if(!value) {
           value = '';
       }
      $('#cc_content').hide();
         if(value=='auth_and_capture') {
          $('#cc_content').show();
          $( "#address" ).rules( "add", { required: true, });
          $( "#city" ).rules( "add", { required: true, });
          $( "#state" ).rules( "add", { required: true, });
          $( "#zip" ).rules( "add", { required: true, });
          $( "#country" ).rules( "add", { required: true, });
        } else if(value=='auth_only') {
          $('#cc_content').show();
          $( "#address" ).rules( "add", { required: true, });
          $( "#city" ).rules( "add", { required: true, });
          $( "#state" ).rules( "add", { required: true, });
          $( "#zip" ).rules( "add", { required: true, });
          $( "#country" ).rules( "add", { required: true, });
        } else {
          $('#cc_content').hide('fast');
          $( "#address" ).rules( "remove");
          $( "#city" ).rules( "remove");
          $( "#state" ).rules( "remove");
          $( "#zip" ).rules( "remove");
          $( "#country" ).rules( "remove");
        }
   }
   toggleCardBlock($('input[name="create_mode"]:checked').val());*/
   
    // Customer creation dropdown
    $('#customer').change(function() {
        if($(this).val()=='new') {
          $('.new-user').show();
        }
        else if($(this).val()=='empty'){
          $('.empty_customer').hide();
        }else{
          $('.new-user').hide('slow');
        }
    });
 
    function increment_value(){
      $(".save_item").each(function(i){
        $(this).val(i);
      })
    }

    $('#customer').trigger('change');
    $('#add-item').click(function(e) {
      var all_divs = $(".item-group").first().clone().appendTo(".item-row");
      all_divs.find('input').val('');
      increment_value();
    });    
  
    $('.item-row').on('click','.remove-item',function(e) {
        var item_count = $( ".remove-item" ).size();
        if(item_count>1)
          $(this).parent().parent().remove();
        increment_value();
    });

/*    $('#order_form').submit(function(e){
        e.preventDefault();
        $.ajax({
            url:this.action,
            type:this.method,
            data:$(this).serialize(),
            success:function(data){
                $button = $form.find('.btn[data-loading-text]:visible:last');
                $button.button('reset');
                var resp = $.parseJSON(data);

                if(resp['status']!=false)
                {
                    var output =  '<div class="alert alert-dismissible alert-success flash-message mt10" role="alert"> <button type="button" class="close" data-hide="flash-message"><span aria-hidden="true">×</span><span class="sr-only">Close</span> </button> <i class="fa fa-check-circle"></i> ' +  resp['message'] + ', refrence #: ' + resp['data'] + '</div>';
                    $('#message').html("");
                    $('#message').html(output);
                }
                else
                {
                    var output =  '<div class="alert alert-dismissible alert-warning flash-message mt10" role="alert"> <button type="button" class="close" data-hide="flash-message"><span aria-hidden="true">×</span><span class="sr-only">Close</span> </button> <i class="fa fa-exclamation-circle"></i> ' +  resp['message'] + '</div>';
                    $('#message').html("");
                    $('#message').html(output);
                }
                $("html, body").animate({ scrollTop: 0 }, "slow");
                $('#message').show('slow').delay(3000);
            },
            error:function(){
                alert("Please Try Again");
            }
        });
        return false;
    });

    $('#invoice_sub').click(function(e) {
        //alert('hi');
        // set our button to loading state
        $button = $('#order_form').find('.btn[data-loading-text]:visible:last');
        $button.button('loading');
        e.preventDefault();
        $.ajax({
            url:'/index.php/User/create_invoice',
            type:'post',
            data:$('#order_form').serialize(),
            success:function(data){
                // alert(data);
                $button = $('#order_form').find('.btn[data-loading-text]:visible:last');
                $button.button('reset');
                var resp = $.parseJSON(data);

                if(resp['status']==true)
                {
                    var output =  '<div class="alert alert-dismissible alert-success flash-message mt10" role="alert"> <button type="button" class="close" data-hide="flash-message"><span aria-hidden="true">×</span><span class="sr-only">Close</span> </button> <i class="fa fa-check-circle"></i>' +  resp['message'] + ', refrence #: ' + resp['data'] + '</div>';
                    $('#message').html("");
                    $('#message').html(output);
                }
                else
                {
                    var output =  '<div class="alert alert-dismissible alert-warning flash-message mt10" role="alert"> <button type="button" class="close" data-hide="flash-message"><span aria-hidden="true">×</span><span class="sr-only">Close</span> </button> <i class="fa fa-exclamation-circle"></i>' +  resp['message'] + '</div>';
                    $('#message').html("");
                    $('#message').html(output);
                }
                $("html, body").animate({ scrollTop: 0 }, "slow");
                $('#message').show('slow').delay(3000);
            },
            error:function(){
                alert("Please Try Again");
            }
        });
        return false;
    }); */

    $('#client_company').change(function(e) {
      e.preventDefault();
      var company_id = $('#client_company').val();
      $.ajax({
          url: js_base_url +'Root/admin_payment_account',
          type: 'get',
          data: 'company_id=' + company_id,
          success:function(data) {
              //alert(data);
              $('#admin_payment_account').html(data);
          },
          error:function() {
              alert("Please Try Again"+company_id);
          }
      });
      return false;
    });

    $('#client_invoice_search').typeahead({
        name: 'client_invoice_search',
        template:['<div class="media br-bottom">',
              '<div class="media-body"><h5 class="media-heading">{{email}}</h5>',
               '<p>{{name}}<p></div></div>'
               ].join(''),
        engine: Hogan,
        remote: js_base_url + 'Admin/table_search/?q=%QUERY&tab=client_invoice'
    });

    $('#staff_invoice_search').typeahead({
        name: 'staff_invoice_search',
        template:['<div class="media br-bottom">',
              '<div class="media-body"><h5 class="media-heading">{{email}}</h5>',
               '<p>{{name}}<p></div></div>'
               ].join(''),
        engine: Hogan,
        remote: js_base_url + 'Admin/table_search/?q=%QUERY&tab=staff_invoices'
    });

    $('#manual_search').typeahead({
        name: 'manual_search',
        template:['<div class="media br-bottom">',
              '<div class="media-body"><h5 class="media-heading">{{email}}</h5>',
               '<p>{{name}}<p></div></div>'
               ].join(''),
        engine: Hogan,
        remote: js_base_url + 'Admin/table_search/?q=%QUERY&tab=manual'
    });

    $('#client_dispute_search').typeahead({
        name: 'client_dispute_search',
        template:['<div class="media br-bottom">',
              '<div class="media-body"><h5 class="media-heading">{{email}}</h5>',
               '<p>{{name}}<p></div></div>'
               ].join(''),
        engine: Hogan,
        remote: js_base_url + 'Admin/table_search/?q=%QUERY&tab=client_dispute'
    });

    $('#transaction_search').typeahead({
        name: 'transaction_search',
        template:['<div class="media br-bottom">',
              '<div class="media-body"><h5 class="media-heading">{{email}}</h5>',
               '<p>{{name}}<p></div></div>'
               ].join(''),
        engine: Hogan,
        remote: js_base_url + 'Admin/table_search/?q=%QUERY&tab=transaction'
    });

    $('#staff_transaction_search').typeahead({
        name: 'staff_transaction_search',
        template:['<div class="media br-bottom">',
              '<div class="media-body"><h5 class="media-heading">{{email}}</h5>',
               '<p>{{name}}<p></div></div>'
               ].join(''),
        engine: Hogan,
        remote: js_base_url + 'Admin/table_search/?q=%QUERY&tab=staff_transaction'
    });

    $('#admin_transaction_search').typeahead({
        name: 'admin_transaction_search',
        template:['<div class="media br-bottom">',
              '<div class="media-body"><h5 class="media-heading">{{email}}</h5>',
               '<p>{{name}}<p></div></div>'
               ].join(''),
        engine: Hogan,
        remote: js_base_url + 'Admin/table_search/?q=%QUERY&tab=admin_transaction'
    });

    $('#admin_lead_search').typeahead({
        name: 'admin_lead_search',
        template:['<div class="media br-bottom">',
              '<div class="media-body"><h5 class="media-heading">{{lead_email}}</h5>',
               '<p>{{lead_name}}<p></div></div>'
               ].join(''),
        engine: Hogan,
        remote: js_base_url + 'Admin/table_search/?q=%QUERY&tab=admin_leads'
    });

    $('#invoice_search').typeahead({
        name: 'invoice_search',
        template:['<div class="media br-bottom">',
              '<div class="media-body"><h5 class="media-heading">{{email}}</h5>',
               '<p>{{name}}<p></div></div>'
               ].join(''),
        engine: Hogan,
        remote: js_base_url + 'Admin/table_search/?q=%QUERY&tab=invoice'
    });

    $('#client_search').typeahead({
        name: 'client_search',
        template:['<div class="media br-bottom">',
              '<div class="media-body"><h5 class="media-heading">{{email}}</h5>',
               '<p>{{name}}<p></div></div>'
               ].join(''),
        engine: Hogan,
        remote: js_base_url + 'Admin/table_search/?q=%QUERY&tab=client'
    });


    $('#subscription_search').typeahead({
        name: 'subscription_search',
        template:['<div class="media br-bottom">',
              '<div class="media-body"><h5 class="media-heading">{{email}}</h5>',
               '<p>{{name}}<p></div></div>'
               ].join(''),
        engine: Hogan,
        remote: js_base_url + 'Admin/table_search/?q=%QUERY&tab=subscription'
    });

    $('#admin_subscription_search').typeahead({
        name: 'admin_subscription_search',
        template:['<div class="media br-bottom">',
              '<div class="media-body"><h5 class="media-heading">{{email}}</h5>',
               '<p>{{name}}<p></div></div>'
               ].join(''),
        engine: Hogan,
        remote: js_base_url + 'Admin/table_search/?q=%QUERY&tab=admin_subscription'
    });

    $('#customer_search').typeahead({
        name: 'customer_search',
        template:['<div class="media br-bottom">',
              '<div class="media-body"><h5 class="media-heading">{{email}}</h5>',
               '<p>{{name}}<p></div></div>'
               ].join(''),
        engine: Hogan,
        remote: js_base_url + 'Admin/table_search/?q=%QUERY&tab=customer'
    });

    $('#staff_customer_search').typeahead({
        name: 'staff_customer_search',
        template:['<div class="media br-bottom">',
              '<div class="media-body"><h5 class="media-heading">{{email}}</h5>',
               '<p>{{name}}<p></div></div>'
               ].join(''),
        engine: Hogan,
        remote: js_base_url + 'Admin/table_search/?q=%QUERY&tab=staff_customer'
    });

    $('#group_search').typeahead({
        name: 'group_search',
        template:['<div class="media br-bottom">',
              '<div class="media-body"><h5 class="media-heading">{{group_name}}</h5></div></div>'
               ].join(''),
        engine: Hogan,
        remote: js_base_url + 'Admin/table_search/?q=%QUERY&tab=group'
    });

    $('#company_search').typeahead({
        name: 'company_search',
        template:['<div class="media br-bottom">',
              '<div class="media-body"><h5 class="media-heading">{{email}}</h5>',
               '<p>{{name}}<p></div></div>'
               ].join(''),
        engine: Hogan,
        remote: js_base_url + 'Admin/table_search/?q=%QUERY&tab=company'
    });

    $('#admin_search').typeahead({
        name: 'admin_search',
        template:['<div class="media br-bottom">',
              '<div class="media-body"><h5 class="media-heading">{{email}}</h5>',
               '<p>{{name}}<p></div></div>'
               ].join(''),
        engine: Hogan,
        remote: js_base_url + 'Admin/table_search/?q=%QUERY&tab=admin'
    });

    $('#root_client_search').typeahead({
        name: 'root_client_search',
        template:['<div class="media br-bottom">',
              '<div class="media-body"><h5 class="media-heading">{{email}}</h5>',
               '<p>{{name}}<p></div></div>'
               ].join(''),
        engine: Hogan,
        remote: js_base_url + 'Admin/table_search/?q=%QUERY&tab=root_client'
    });

    $('#root_rep_search').typeahead({
        name: 'root_rep_search',
        template:['<div class="media br-bottom">',
              '<div class="media-body"><h5 class="media-heading">{{rep_email}}</h5>',
               '<p>{{rep_name}}<p></div></div>'
               ].join(''),
        engine: Hogan,
        remote: js_base_url + 'Admin/table_search/?q=%QUERY&tab=root_rep'
    });

    $('#rep_search').typeahead({
        name: 'rep_search',
        template:['<div class="media br-bottom">',
              '<div class="media-body"><h5 class="media-heading">{{rep_name}}</h5>',
               '<p>{{rep_email}}<p></div></div>'
               ].join(''),
        engine: Hogan,
        remote: js_base_url + 'Admin/table_search/?q=%QUERY&tab=admin_rep_search'
    });

    $('#referral_search').typeahead({
        name: 'referral_search',
        template:['<div class="media br-bottom">',
              '<div class="media-body"><h5 class="media-heading">{{referral_name}}</h5>',
               '<p>{{referral_email}}<p></div></div>'
               ].join(''),
        engine: Hogan,
        remote: js_base_url + 'Admin/table_search/?q=%QUERY&tab=admin_referral_search'
    });

    $('#sales_manager_search').typeahead({
        name: 'sales_manager_search',
        template:['<div class="media br-bottom">',
              '<div class="media-body"><h5 class="media-heading">{{sales_manager_name}}</h5>',
               '<p>{{sales_manager_email}}<p></div></div>'
               ].join(''),
        engine: Hogan,
        remote: js_base_url + 'Admin/table_search/?q=%QUERY&tab=admin_sales_manager_search'
    });

    $('#error_code_search').typeahead({
        name: 'error_code_search',
        template:['<div class="media br-bottom">',
              '<div class="media-body"><h5 class="media-heading">{{error_id}}</h5>',
               '<p>{{error_message}}<p></div></div>'
               ].join(''),
        engine: Hogan,
        remote: js_base_url + 'Admin/table_search/?q=%QUERY&tab=errorlogs'
    });

    $('#admin_users').typeahead({
        name: 'admin_users',
        template:['<div class="media br-bottom">',
              '<div class="media-body"><h5 class="media-heading">{{user_id}} {{username}}</h5>',
               '<p>{{email}}</p></div></div>'
               ].join(''),
        engine: Hogan,
        remote: js_base_url + 'Admin/table_search/?q=%QUERY&tab=client_user'
    });

     $('#doc_client_name').typeahead({
        name: 'doc_client_name',
        template:['<div class="media br-bottom">',
              '<div class="media-body"><h5 class="media-heading">{{name}}</h5>',
               '<p>{{email}}</p></div></div>'
               ].join(''),
        engine: Hogan,
        remote: js_base_url + 'Admin/table_search/?q=%QUERY&tab=doc_client_search'
    });

    $('#document_search').typeahead({
        name: 'document_search',
        template:['<div class="media br-bottom">',
              '<div class="media-body"><h5 class="media-heading">{{document_name}}</h5></div></div>'
               ].join(''),
        engine: Hogan,
        remote: js_base_url + 'Admin/table_search/?q=%QUERY&tab=search_document_name'
    });

    $('#search_customer_document').typeahead({
        name: 'search_customer_document',
        template:['<div class="media br-bottom">',
              '<div class="media-body"><h5 class="media-heading">{{document_name}}</h5></div></div>'
               ].join(''),
        engine: Hogan,
        remote: js_base_url + 'Admin/table_search/?q=%QUERY&tab=search_customer_document'
    });

    $('#sel_client_name').typeahead({
        name: 'sel_client_name',
        template:['<div class="media br-bottom">',
              '<div class="media-body"><h5 class="media-heading">{{email}}</h5>',
               '<p>{{name}}</p></div></div>'
               ].join(''),
        engine: Hogan,
        remote: js_base_url + 'Admin/table_search/?q=%QUERY&tab=doc_client_search'
    });

    $('#customer_card').typeahead({
        name: 'customer_card',
        template:['<div class="media br-bottom">',
              '<div class="media-body"><h5 class="media-heading">{{email}}</h5>',
               '<p>{{name}}</p></div></div>'
               ].join(''),
        engine: Hogan,
        remote: js_base_url + 'Admin/table_search/?q=%QUERY&tab=customer_card_search'
    });

    $('#customer_account').typeahead({
        name: 'customer_account',
        template:['<div class="media br-bottom">',
              '<div class="media-body"><h5 class="media-heading">{{email}}</h5>',
               '<p>{{name}}</p></div></div>'
               ].join(''),
        engine: Hogan,
        remote: js_base_url + 'Admin/table_search/?q=%QUERY&tab=customer_account_search'
    });

    $('#item_search').typeahead({
        name: 'item_search',
        template:['<div class="media br-bottom">',
              '<div class="media-body"><h5 class="media-heading">{{invoice_item}}</h5></div></div>'
               ].join(''),
        engine: Hogan,
        remote: js_base_url + 'Admin/table_search/?q=%QUERY&tab=item_search'
    });

    $('#client_default_items').typeahead({
        name: 'client_default_items',
        template:['<div class="media br-bottom">',
              '<div class="media-body"><h5 class="media-heading">{{email}}</h5>',
               '<p>{{name}}</p></div></div>'
               ].join(''),
        engine: Hogan,
        remote: js_base_url + 'Admin/table_search/?q=%QUERY&tab=invoice_item_client_search'
    });

    $('#sel_doc_type').typeahead({
        name: 'sel_doc_type',
        template:['<div class="media br-bottom">',
              '<div class="media-body"><h5 class="media-heading">{{document_type}}</h5></div></div>'
               ].join(''),
        engine: Hogan,
        remote: js_base_url + 'Admin/table_search/?q=%QUERY&tab=search_document_type'
    });

    $('#message_users,#add_message_users,#edit_message_users').typeahead({
        name: 'message_users',
        template:['<div class="media br-bottom">',
              '<div class="media-body"><h5 class="media-heading">{{user_id}} {{username}}</h5>',
               '<p>{{name}}</p></div></div>'
               ].join(''),
        engine: Hogan,
        remote: js_base_url + 'Admin/table_search/?q=%QUERY&tab=message_user'
    });

    $('#staff_search').typeahead({
        name: 'staff_search',
        template:['<div class="media br-bottom">',
              '<div class="media-body"><h5 class="media-heading">{{email}}</h5>',
               '<p>{{name}}<p></div></div>'
               ].join(''),
        engine: Hogan,
        remote: js_base_url + 'Admin/table_search/?q=%QUERY&tab=staff'
   });

    $('#stripe_account_name').typeahead({
        name: 'stripe_account_name',
        template:['<div class="media br-bottom">',
                  '<div class="media-body"><p>{{name}}</p></div></div>',
                 ].join(''),
        engine: Hogan,
        remote: js_base_url + 'Admin/table_search/?q=%QUERY&tab=admin_account_stripe'
    });

    $('#propay_account_name').typeahead({
        name: 'propay_account_name',
        template:['<div class="media br-bottom">',
                  '<div class="media-body"><p>{{name}}</p></div></div>',
                 ].join(''),
        engine: Hogan,
        remote: js_base_url + 'Admin/table_search/?q=%QUERY&tab=admin_account_propay'
    });

    $('#authnet_account_name').typeahead({
        name: 'authnet_account_name',
        template:['<div class="media br-bottom">',
                  '<div class="media-body"><p>{{name}}</p></div></div>',
                 ].join(''),
        engine: Hogan,
        remote: js_base_url + 'Admin/table_search/?q=%QUERY&tab=admin_account_authnet'
    });

    $('#nmi_account_name').typeahead({
        name: 'nmi_account_name',
        template:['<div class="media br-bottom">',
                  '<div class="media-body"><p>{{name}}</p></div></div>',
                 ].join(''),
        engine: Hogan,
        remote: js_base_url + 'Admin/table_search/?q=%QUERY&tab=admin_account_nmi'
    });

    $('#transfirst_account_name').typeahead({
        name: 'transfirst_account_name',
        template:['<div class="media br-bottom">',
                  '<div class="media-body"><p>{{name}}</p></div></div>',
                 ].join(''),
        engine: Hogan,
        remote: js_base_url + 'Admin/table_search/?q=%QUERY&tab=admin_account_transfirst'
    });

    $('#archive_search').typeahead({
        name: 'archive_search',
        template:['<div class="media br-bottom">',
              '<div class="media-body"><h5 class="media-heading">{{email}}</h5>',
               '<p>{{name}}<p></div></div>'
               ].join(''),
        engine: Hogan,
        remote: js_base_url + 'Admin/table_search/?q=%QUERY&tab=archive_transaction'
    });

    $('#admin_archive').typeahead({
        name: 'admin_archive',
        template:['<div class="media br-bottom">',
              '<div class="media-body"><h5 class="media-heading">{{email}}</h5>',
               '<p>{{name}}<p></div></div>'
               ].join(''),
        engine: Hogan,
        remote: js_base_url + 'Admin/table_search/?q=%QUERY&tab=admin_archive_transaction'
    });

    $('#staff_archive_search').typeahead({
        name: 'staff_archive_search',
        template:['<div class="media br-bottom">',
              '<div class="media-body"><h5 class="media-heading">{{email}}</h5>',
               '<p>{{name}}<p></div></div>'
               ].join(''),
        engine: Hogan,
        remote: js_base_url + 'Admin/table_search/?q=%QUERY&tab=staff_archive_transaction'
   });
    $('#staff_archive_invoices').typeahead({
        name: 'staff_archive_invoices',
        template:['<div class="media br-bottom">',
              '<div class="media-body"><h5 class="media-heading">{{email}}</h5>',
               '<p>{{name}}<p></div></div>'
               ].join(''),
        engine: Hogan,
        remote: js_base_url + 'Admin/table_search/?q=%QUERY&tab=staff_archive_invoices'
   });

    $('#client_archive_invoices').typeahead({
        name: 'client_archive_invoices',
        template:['<div class="media br-bottom">',
              '<div class="media-body"><h5 class="media-heading">{{email}}</h5>',
               '<p>{{name}}<p></div></div>'
               ].join(''),
        engine: Hogan,
        remote: js_base_url + 'Admin/table_search/?q=%QUERY&tab=client_archive_invoices'
    });

    $('#admin_archive_invoices').typeahead({
        name: 'admin_archive_invoices',
        template:['<div class="media br-bottom">',
              '<div class="media-body"><h5 class="media-heading">{{email}}</h5>',
               '<p>{{name}}<p></div></div>'
               ].join(''),
        engine: Hogan,
        remote: js_base_url + 'Admin/table_search/?q=%QUERY&tab=admin_archive_invoices'
    });

    $('.input-daterange').datepicker({ endDate: '-0m', orientation: 'left'});
    

    /*To display bootstrap tooltip */
    $("body").tooltip({ selector: '[data-toggle=tooltip]' });

    jQuery("body").on('click', '.copy_text', function(event) {
      var copyElement = jQuery(this);
      copyElement.text("copied");
      setTimeout(function(){ copyElement.text("click to copy"); }, 3000);
      copyTextToClipboard(copyElement.attr("data-title"));
    });

});

function refresh_table(table) {

	var $table = $('#'+table);
    $table.bootstrapTable('selectPage', 1);
	$table.bootstrapTable('refresh');
}
function table_payment_params(params) {

    $('#toolbar_payment').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    $('#toolbar_payment').find('select[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    console.log('Data:',params);
    return params;

}
function table_payout_params(params) {

    $('#toolbar_payout').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    $('#toolbar_payout').find('select[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    console.log('Data:',params);
    return params;

}
function table_subscription_params(params) {

    $('#toolbar_subscription').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
     $('#toolbar_subscription').find('select[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    console.log('Data:',params);
    return params;

}
function table_invoice_params(params) {

    $('#toolbar_invoice').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    $('#toolbar_invoice').find('select[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    console.log('Data:',params);
    return params;

}

function table_archive_invoices(params) {

    $('#toolbar_archive_invoices').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    $('#toolbar_archive_invoices').find('select[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    console.log('Data:',params);
    return params;

}

function table_admin_archive_invoices(params) {

    $('#toolbar_admin_archive_invoice').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    $('#toolbar_admin_archive_invoice').find('select[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    console.log('Data1:',params);
    return params;

}


function table_admin_archive_params(params) {

    $('#toolbar_admin_archive').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    $('#toolbar_admin_archive').find('select[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    console.log('Data:',params);
    return params;

}

function table_archive_params(params) {

    $('#toolbar_archive').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    $('#toolbar_archive').find('select[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    console.log('Data:',params);
    return params;

}

function table_staff_archive(params) {

    $('#toolbar_archive').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    $('#toolbar_archive').find('select[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    console.log('Data:',params);
    return params;

}
function table_items_params(params) {
    $('#toolbar_default_items').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    console.log('Data:',params);
    return params;

}

function table_onboard_params(params) {
    $('#toolbar_onboard').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    console.log('Data:',params);
    return params;

}

function table_cards_params(params) {
    $('#toolbar_card').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    console.log('Data:',params);
    return params;

}

function table_accounts_params(params) {
    $('#toolbar_account').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    console.log('Data:',params);
    return params;

}

function table_dispute_params(params) {

    $('#toolbar_dispute').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    console.log('Data:',params);
    return params;

}
function table_client_params(params) {

    $('#toolbar_client').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    console.log('Data:',params);
    return params;

}

function table_referral_params(params) {

    $('#toolbar_referral').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    console.log('Data:',params);
    return params;

}

function table_member_params(params) {

    $('#toolbar_member').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    console.log('Data:',params);
    return params;

}

function table_imported_transaction_params(params) {

    /*$('#toolbar_import_transaction').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });*/
    console.log('Data:',params);
    return params;

}
function table_failed_transaction_params(params) {

    /*$('#toolbar_failed_import').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });*/
    console.log('Data:',params);
    return params;

}

function table_signup_params(params) {

    $('#toolbar_signup').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    console.log('Data:',params);
    return params;

}

function table_deactivation_params(params) {

    /*$('#toolbar_deactivation').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });*/
    console.log('Data:',params);
    return params;

}

function table_referral_application_params(params) {

    /*$('#table_referral_application').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });*/
    console.log('Data:',params);
    return params;

}

function table_customer_params(params) {

    $('#toolbar_customer').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    $('#toolbar_customer').find('select[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    return params;

}

// function table_settlement_params(params) {

//     $('#toolbar_settlement').find('input[name]').each(function () {
//         params[$(this).attr('name')] = $(this).val();
//     });
//     $('#toolbar_settlement').find('select[name]').each(function () {
//         params[$(this).attr('name')] = $(this).val();
//     });
//     console.log('Data:',params);
//     return params;

// }

function table_settlement_transaction_params(params) {

    $('#toolbar_settlements_transaction').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    $('#toolbar_settlements_transaction').find('select[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    console.log('Data:',params);
    return params;

}

function table_settlement_summary_params(params) {

    $('#toolbar_settlement_summary').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    $('#toolbar_settlement_summary').find('select[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    console.log('Data:',params);
    return params;

}

function table_transaction_params(params) {

    $('#toolbar_transaction').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    $('#toolbar_transaction').find('select[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    console.log('Data:',params);
    return params;

}

function table_payrequest_params(params) {

    $('#toolbar_payrequest').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    $('#toolbar_payrequest').find('select[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    console.log('Data:',params);
    return params;

}

function table_company_params(params) {

    $('#toolbar_company').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    console.log('Data:',params);
    return params;

}
function table_admin_params(params) {

    $('#toolbar_admin').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    console.log('Data:',params);
    return params;

}
function table_root_client_params(params) {

    $('#toolbar_client').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    console.log('Data:',params);
    return params;

}

function table_root_rep_params(params) {

    $('#toolbar_rep').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    console.log('Data:',params);
    return params;

}
function table_admin_page_params(params) {

    $('#toolbar_page').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    console.log('Data:',params);
    return params;

}

function table_root_lead_params(params) {

    $('#toolbar_lead').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    console.log('Data:',params);
    return params;

}

function table_errorlogs_params(params) {

    $('#toolbar_errorlogs').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    $('#toolbar_errorlogs').find('select[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    console.log('Data:',params);
    return params;
}

function table_manual_params(params) {

    $('#toolbar_manual').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    console.log('Data:',params);
    return params;

}

/**
 * [table_user_message_params - function used to get the users related to messaging features]
 * @param  {[array]} params [Array of parameters to be search]
 * @return {[obj]}          [resultant users as object]
 */
function table_user_message_params(params) {

    $('#toolbar_user_message').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    console.log('Data:',params);
    return params;

}

function table_client_staff_params(params) {

    $('#toolbar_staff').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    console.log('Data:',params);
    return params;

}

function table_client_group_params(params) {

    $('#toolbar_group').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    console.log('Data:',params);
    return params;

}

/**
 * [table_user_statments_params - function used to get the users related to statemnts]
 * @param  {[array]} params [Array of parameters to be search]
 * @return {[obj]}          [resultant users as object]
 */
function table_user_statments_params(params) {

    $('#toolbar_statments').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    console.log('Data:',params);
    return params;

}

/**
 * [table_user_deposit_params - function used to get the users related to deposit via IMG]
 * @param  {[array]} params [Array of parameters to be search]
 * @return {[obj]}          [resultant users as object]
 */
function table_user_deposit_params(params) {

    $('#toolbar_deposit').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    console.log('Data:',params);
    return params;
}

function table_admin_stripe_settings(params) {

    $('#toolbar_stripeSettings').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    console.log('Data:',params);
    return params;
}

function table_admin_propay_settings(params) {

    $('#toolbar_propaySettings').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    console.log('Data:',params);
    return params;
}

function table_admin_authnet_settings(params) {

    $('#toolbar_authenetSettings').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    console.log('Data:',params);
    return params;
}

function table_admin_nmi_settings(params) {

    $('#toolbar_nmiSettings').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    console.log('Data:',params);
    return params;
}

function table_admin_transfirst_settings(params) {

    $('#toolbar_transfirstSettings').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    console.log('Data:',params);
    return params;
}

function table_admin_stripe_clients(params) {

    $('#toolbar_stripeClients').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    console.log('Data:',params);
    return params;
}

function table_admin_propay_clients(params) {

    $('#toolbar_propayClients').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    console.log('Data:',params);
    return params;
}

function table_admin_nmi_clients(params) {

    $('#toolbar_nmiClients').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    console.log('Data:',params);
    return params;
}

function table_admin_authnet_clients(params) {

    $('#toolbar_authnetClients').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    console.log('Data:',params);
    return params;
}

function table_admin_transfirst_clients(params) {

    $('#toolbar_transfirstClients').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    console.log('Data:',params);
    return params;
}

/**
 * [table_rep_params - function used to get the reps]
 * @param  {[array]} params [Array of parameters to be search]
 * @return {[obj]}          [resultant users as object]
 */
function table_rep_params(params) {

    $('#toolbar_rep').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    console.log('Data:',params);
    return params;

}

function totalTextFormatter(data) {
    return 'Total';
}

function totalAmountFormatter(data) {
    var total = 0;
    var status = $('#status').val();console.log('Data:',status);

    if (status == 'All' || status == 'Partial_refund') {
      $.each(data, function (i, row) {

        var balance = parseFloat(row.balance.replace(',','').substring(1));

        if ((row.status1 == 'paid' || row.status1 == 'partial_refund') && balance > 0) {
          total += +(parseFloat(row.balance.replace(',','').substring(1)));
          //console.log(row.invoice_id,'1status1:',row.status1);
        } else if (row.status1 != 'paid') {
          total += +(parseFloat(row.amount.replace(',','').substring(1)));
          //console.log(row.invoice_id,'2status1:',row.status1);
        }

      });
    } else {
      $.each(data, function (i, row) {
        total += +(parseFloat(row.amount.replace(',','').substring(1)));
      });
    }
    return '$' + total.toFixed(2);
}

function reportHandler(res) {

    if(res.sum==null){
      $('#total_amount').html('0.00');
    }else{
      $('#total_amount').html(res.sum);
    }
    $('#total_refund').html(res.totalrefund);
    return res;
}

function copyTextToClipboard(text) {
  var textArea = document.createElement("textarea");

  // Place in top-left corner of screen regardless of scroll position.
  textArea.style.position = 'fixed';
  textArea.style.top = 0;
  textArea.style.left = 0;

  // Ensure it has a small width and height. Setting to 1px / 1em
  // doesn't work as this gives a negative w/h on some browsers.
  textArea.style.width = '2em';
  textArea.style.height = '2em';

  // We don't need padding, reducing the size if it does flash render.
  textArea.style.padding = 0;

  // Clean up any borders.
  textArea.style.border = 'none';
  textArea.style.outline = 'none';
  textArea.style.boxShadow = 'none';

  // Avoid flash of white box if rendered for any reason.
  textArea.style.background = 'transparent';


  textArea.value = text;

  document.body.appendChild(textArea);

  textArea.select();

  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
    console.log('Copying text command was ' + msg);
  } catch (err) {
    console.log('Oops, unable to copy');
  }

  document.body.removeChild(textArea);
}

function refund(id, client_id,balance, page_id)
{
  $('#page_id').val(page_id);
  $('#payment_id').val(id);
  $('#client_id').val(client_id);
  $('#refund_balance').html(balance);
}

function cancel_ach(id, client_id)
{
  $('#cancel_payment_id').val(id);
  $('#cancel_client_id').val(client_id);
}

function cancel_payouts(id, client_id)
{
  $('#cancel_payment_id').val(id);
  $('#cancel_client_id').val(client_id);
}

function add_group(group_id)
{
  $('#modal_group_id').val(group_id);
}

function mail_sent(id,company_id){
   $('#id').val(id);
   $('#cid').val(company_id);
}

function deactivate(id,company_id){
   $('#client_id').val(id);
   $('#company_id').val(company_id);
}


function view_group(group_id)
{
  $('#group_id').val(group_id);
    $.ajax({
        url: js_base_url + 'User/view_members',
        type: 'post',
        data: {'group_id':group_id},
        success:function(data){
          var resp = $.parseJSON(data);

          if(resp['status']==true)
            {
              var group = '<table class="items-table table table-striped table-hover"><thead><tr><td><b>Group Name</b></td><td><b>Staff ID</b></td><td><b>Staff Name</b></td></tr></thead>';
               for(var i=0;i<resp['list'].length;i++)
               {
                  group = group + '<tr><td>' + resp['list'][i]['group_name'] + '</td><td>' + resp['list'][i]['staff_id'] + '</td><td>' + resp['list'][i]['staff_name'] + '</td><td> ' + '<a href=' + js_base_url + 'User/delete_members/?staff_id='+ resp['list'][i]['staff_id'] + '&group_id=' + resp['list'][i]['group_id'] + ' class="btn btn-sm btn-primary"> Delete</a> </td></tr>';
               }
              group = group + '</table>';

              $('#modal_button').click();
              $('#list_group').html(group);
            }
            else
            {
              var group = 'Group Members is empty..!';
              $('#modal_button').click();
              $('#list_group').html(group);  
            }

        },
        error:function(){
            alert("Please Try Again");
        }
    });

}

/*function dispute_refund(id, client_id,balance)
{
  $('#payment_id').val(id);
  $('#client_id').val(client_id);
  $('#refund_balance').html(balance);
}*/

$(document).ready(function() {
  
  $('#balance_modal').on('hidden.bs.modal', function () {
        window.location.reload();
    });

 $('#check_balance').click(function() {
      $button = $(this);
      $button.button('loading');
        $.ajax({
            url:'/User/get_balance',
            type:'post',
            success:function(data){
              $button.button('reset');

                var resp = $.parseJSON(data);

                if(resp['status']!=false)
                {
                    var output =  "Available balance : " +  resp['available'] + ', Pending Balance: ' + resp['pending'] + '</div>';
                    $('#balance1').html("");
                    $('#balance1').html(output);
                    
                }
                else
                {
                    var output =  resp['message'];
                    $('#err_msg').html("");
                    $('#err_msg').html(output);
                }
                $('#balance_button').click();
            },
            error:function(){
                alert("Please Try Again");
            }
        });
        return false;
    });
    });

$(document).ready(function() {
$('#card_data').hide();
$('#bank_data').hide();

 $('#type').change(function(e){
  var type = $(this).val();
    if(type=='card'){
      $('#bank_data').hide();
      $('#card_data').show();
    }else if(type=='bank'){
      $('#card_data').hide();
      $('#bank_data').show();
    }else{
      $('#card_data').hide();
      $('#bank_data').hide();
    }

  });
 });


$(document).ready(function() {

   /*reload page on closing modal window*/
   
    $('#myModal').on('hidden.bs.modal', function () {
        window.location.reload();
    });

    $('#transfer_schedule_modal').on('hidden.bs.modal', function () {
        window.location.reload();
    });

$('#transfer_now').click(function(e){
        $button = $('#myForm');
        $button.button('loading');
        $.ajax({
            url: js_base_url + 'User/card_transfer',
            type: 'post',
            data:$('#myForm').serialize(),
            success:function(data){
              $button = $('#myForm').find('.btn[data-loading-text]:visible:last');
              $button.button('reset');

              var resp = $.parseJSON(data);
              // console.log(data);

              if(resp['status']==true)
              {
                var output =  '<div class="alert alert-dismissible alert-success flash-message mt10" role="alert"> <button type="button" class="close" data-hide="flash-message"><span aria-hidden="true">×</span><span class="sr-only">Close</span> </button> <i class="fa fa-check-circle"></i> ' +  resp['message'] + '</div>';
                $('#message_modal').css('color','black');
              }
              else
              {
                if(resp['message'] == 'logout'){

                    window.location='/User/logout';

                }
                else if(resp['message'] == 'Please try again later..!!'){
                  $('#message_modal').css('color','black');
                  setTimeout("location.reload(true);",25000);
                }
                else{
                  var output =  '<div class="alert alert-dismissible alert-warning flash-message mt10" role="alert"> <button type="button" class="close" data-hide="flash-message"><span aria-hidden="true">×</span><span class="sr-only">Close</span> </button> <i class="fa fa-exclamation-circle"></i> ' +  resp['message'] + '</div>';
                  $('#message_modal').css('color','red');
                }
              }
              $('#message_modal').html("");
              $('#message_modal').html(output);
              $('#my_csrf').attr('name',resp['nonce_name']);
              $('#my_csrf').val(resp['nonce_value']);
            },
            error:function(){
                alert("Please Try Again");
            }
        });
        return false;
    });

  });

$(document).ready(function() {

  $("#switch_mode").bootstrapSwitch({
    onText: 'Auto',
    offText: 'manual'
  });

  $('#interval').change( function() {

      var interval = $(this).val();
      if(interval == 'daily'){
        $('#weekly').hide();
        $('#monthly').hide();
        $('#daily').show();
      } else if(interval == 'weekly'){
        $('#daily').hide();
        $('#monthly').hide();
        $('#weekly').show();
      }else{
        $('#daily').hide();
        $('#weekly').hide();
        $('#monthly').show();
      }

  });

  $('#switch_mode').on('switchChange.bootstrapSwitch', function (event, state) {

    if(state) {

       $('#transfer_mode_button').click();
       var interval = $('#interval').val();
       if(interval == 'daily'){
        $('#weekly').hide();
        $('#monthly').hide();
       }
    }
    else {

        $.ajax({
          url: js_base_url + 'User/transfer_schedule',
          type: 'post',
          data: {transfer_mode:0},
              success:function(data){

            var resp = $.parseJSON(data);

            if(resp['status']==true)
            {
              var output = resp['message'];
              $('#manual_mode').css('color','black');
            }
            else
            {
              var output = resp['message'];
              $('#manual_mode').css('color','red');
            }

            $('#manual_mode').html("");
            $('#manual_mode').html(output);

          },
          error:function(){
                alert("Please Try Again");
            }

        })
      }
    })
});


$(document).ready(function() {
$('#send_schedule').click(function(e){
        $button = $(this);
        $button.button('loading');
        $.ajax({
            url: js_base_url + 'User/transfer_schedule',
            type: 'post',
            data: $('#transfer_schedule').serialize()+'&transfer_mode=1',
            success:function(data){
                $button.button('reset');

              var resp = $.parseJSON(data);

              if(resp['status']==true)
                {
                  var output =  '<div class="alert alert-dismissible alert-success flash-message mt10" role="alert"> <button type="button" class="close" data-hide="flash-message"><span aria-hidden="true">×</span><span class="sr-only">Close</span> </button> <i class="fa fa-check-circle"></i> ' +  resp['message'] + '</div>';
                  $('#current_mode').css('color','black');
                }
                else
                {
                  var output =  '<div class="alert alert-dismissible alert-warning flash-message mt10" role="alert"> <button type="button" class="close" data-hide="flash-message"><span aria-hidden="true">×</span><span class="sr-only">Close</span> </button> <i class="fa fa-exclamation-circle"></i> ' +  resp['message'] + '</div>';
                  $('#current_mode').css('color','red');
                }

                $('#current_mode').html("");
                $('#current_mode').html(output);

            },
            error:function(){
                alert("Please Try Again");
            }
        })
      })

    });

///// New on 2016/09/28 for Document upload - by RM
function table_document_params(params) {

    $('#toolbar_document').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    $('#toolbar_document').find('select[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    return params;
}

function table_hostedpage_params(params) {

    $('#toolbar_hostedpage').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    $('#toolbar_hostedpage').find('select[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    return params;
}

function table_page_expired_params(params) {

    $('#toolbar_pageexpired').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    $('#toolbar_pageexpired').find('select[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    return params;
}

function table_partners_params(params) {

    $('#toolbar_partners').find('input[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    $('#toolbar_partners').find('select[name]').each(function () {
        params[$(this).attr('name')] = $(this).val();
    });
    return params;
}


// New on 2016/12/02 for update subscription card changed after card mannagement  updated on 2016/12/19 - by RM
$(document).ready(function() {
    $('#save_checkbox').hide('slow');
    $('#default_checkbox').hide('slow');

    if($('#customer').val()=='new'){
      $('#card_input').hide('slow');
      $('#save_checkbox').hide('slow');
      $('#default_checkbox').hide('fast');  
    }

    $('#subscription_card').change( function() {
      if($('#subscription_card').val()=='new') {
        $('#card_input').show('fast');        
        $('#save_checkbox').show('fast');
        $('#default_checkbox').show('fast');
      }else if($('#subscription_card').val()=='empty' || $('#subscription_card').val()==''){
        $('#card_input').hide('slow');
        $('#save_checkbox').hide('slow');
        $('#default_checkbox').hide('slow');        
      }
      else {
        $('#card_input').hide('slow');
        $('#save_checkbox').hide('slow');
        // hide default checkbox for existing default card
        if($(this).find(":selected").data('default')) {
          $('#default_checkbox').hide('fast');  
        } else {
            $('#default_checkbox').show('fast');
        }     
      }
    });

});

//New on 2016/12/19 for paylater payment form 
$(document).ready(function() {
    $('a.active').closest('.sub-menu').collapse('show');
    $('#save_checkbox').hide('slow');
    $('#default_checkbox').hide('slow');
    $('#card_det').hide('slow');

    $('#payment_card').change( function() {

      if($('#payment_card').val()=='new') { 
        $('#card_input').show('fast');        
        $('#card_det').show('fast');      
        $('#save_checkbox').show('fast');
        $('#default_checkbox').show('fast');
      }else if($('#payment_card').val()=='empty' || $('#payment_card').val()==''){ 
        $('#card_input').hide('slow');
        $('#card_det').hide('slow');
        $('#save_checkbox').hide('slow');
        $('#default_checkbox').hide('slow');        
      }
      else { 
        $('#card_input').hide('slow');
        $('#save_checkbox').hide('slow');
        $('#default_checkbox').show('fast');
        $('#card_det').show('fast'); 
      }

    });
});