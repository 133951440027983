var app = {

    /*
     * init is where we initialize everything
     */
    init: function() {
        this.addValidation();
        this.bootstrap();
        this.tableSearch();
    },

    /*
    * setup our bootstrap functionality
    */
    bootstrap: function() {

        $('[data-toggle="tooltip"]').tooltip({html: true});
        $('[data-toggle="popover"]').popover();

        if ( $('.nav-tabs').length ) {
            if ( $.jGet('tab') ) {
                $('.nav-tabs a[href="#' + $.jGet('tab') + '"]').tab('show');
            } else {
                $('.nav-tabs a:first').tab('show');
                if ( $('.nav-tabs.hash-tabs').length ) {
                    window.location.hash = '#tab=' + $('.nav-tabs.hash-tabs a:first').attr('href').substr(1);
                }
            }
            $('.hash-tabs a[data-toggle="tab"]').off('shown.bs.tab').on('shown.bs.tab', function(e) {
                window.location.hash = '#tab=' + e.target.hash.substr(1);
            });
        }

        // show last settings pane if it's set
        if ( localStorage.activePill ) {
            $('.nav-pill-control > li').removeClass('active');
            $('.nav-pill-control > li > a[href="' + localStorage.activePill + '"]').parent().addClass('active');
            $('.nav-pill-pane').hide();
            $(localStorage.activePill).show();
        }

        $('[data-hide]').on('click', function() {
            if ( $(this).parent().hasClass('modal-header') ) {
                $('#' + $(this).attr('data-hide')).modal('hide')
            } else {
                $(this).closest('.' + $(this).attr('data-hide')).hide();
            }
        });

        $('#add_item, #create_invoice').on('shown.bs.modal', function(e) {
            $(this).find('input:text:first').focus();
        });

        $('#edit_item').on('show.bs.modal', function(e) {
            var $tr = $(e.relatedTarget).closest('tr');
            $('#edit_item input[name="id"]').val($tr.attr('data-item-id'));
            $('#edit_item input[name="name"]').val($tr.attr('data-item-name'));
            $('#edit_item input[name="price"]').val($tr.attr('data-item-price'));
        });

        $('.datepicker').datepicker();

        $(document.body).on('click', '.confirm-click1', function(e) {
            e.preventDefault();
            $this = $(this);
            var text = $this.attr('data-label') ? $this.attr('data-label') : 'Yes, delete it!';
            var alert_title = $this.attr('data-title') ? $this.attr('data-title') : 'Are you sure?';
            var alert_msg = $this.attr('data-msg') ? $this.attr('data-msg') : 'This action cannot be undone.';
            var alert_type = $this.attr('data-icon') ? $this.attr('data-icon') : 'warning';
            swal({
                title: alert_title,
                text: alert_msg,
                type: alert_type,
                showCancelButton: true,
                confirmButtonClass: 'btn-danger',
                confirmButtonText: text,
                closeOnConfirm: false
            }, function() {
                window.location = $this.attr('href');
            });

        });

        $(document.body).on('click', '.confirm-click', function(e) {
            e.preventDefault();
            $this = $(this);
            var text = $this.attr('data-label') ? $this.attr('data-label') : 'Yes, delete it!';
            var alert_title = $this.attr('data-title') ? $this.attr('data-title') : 'Are you sure?';
            var alert_msg = $this.attr('data-msg') ? $this.attr('data-msg') : 'This action cannot be undone.';
            var alert_type = $this.attr('data-icon') ? $this.attr('data-icon') : 'warning';
            swal({
                title: alert_title,
                text: alert_msg,
                type: alert_type,
                showCancelButton: true,
                confirmButtonClass: 'btn-danger',
                confirmButtonText: text,
                closeOnConfirm: false
            }, function() {
                window.location = $this.attr('href');
            });

        });

        $('.confirm-download').on('click', function(e) {
            e.preventDefault();
            $this = $(this);
            var text = $this.attr('data-text') ? $this.attr('data-text') : 'Yes, Download it!';
            swal({
                title: 'Are you sure?',
                text: 'Do you want to download the file',
                type: 'info',
                showCancelButton: true,
                confirmButtonClass: 'btn-success',
                confirmButtonText: text,
                closeOnConfirm: true
            }, function() {
                window.location = $this.attr('href');
            });

        });

        $('.nav-pill-control a').on('click', function(e) {
            e.preventDefault();
            $('.nav-pill-control > li').removeClass('active');
            $(this).parent().addClass('active');
            $('.nav-pill-pane').hide();
            $($(this).attr('href')).show();
            localStorage.activePill = $(this).attr('href');
        });

        $('.maxlength[maxlength]').maxlength({
            alwaysShow: false,
            threshold: 20,
            showCharsTyped: true,
            placement: 'bottom',
            warningClass: 'label label-success',
            limitReachedClass: 'label label-danger',
            separator: ' of ',
            validate: true
        });

        //Click event of the view button displays the message in modal box and mark it as read.
        $(document.body).on('click', '.btn-msg-view', function(e) {

            var btn = $(this);
            var msg_id = $(this).data('msg_id');
            var url = '/message/messageaction/';
            $.ajax({
                type : 'POST',
                data : {msg_id:msg_id,view:'view'},
                url  : url,
                success : function(data){ 
                    var msg_detail = JSON.parse(data);
                    $('#user_message_view').html(msg_detail.message.user_message);
                    $('#user_msg_id').val(msg_id);
                    $('.table').bootstrapTable('refresh');
                },
                error : function(e){ alert('Error on changing the status');},
            });
        });

        //Click event of the edit button displays the message in modal box for editing.
        $(document.body).on('click', '.btn-msg-edit', function(e) {
            var btn = $(this);
            var msg_id = $(this).data('msg_id');
            var user_message = '';
            var username = '';
            $.ajax({
                url: '/message/messageaction',
                data : {msg_id:msg_id,edit:'edit'},
                type: 'post',
                success: function(data){
                    $('.wysihtml5-msgbox-edit').wysihtml5({toolbar:{"link": false,"image": false}});
                    var msg_detail = JSON.parse(data);
                    console.log(msg_detail.message);
                    user_message = msg_detail.message.user_message;
                    username = msg_detail.message.username;
                    $('#edit_message_users').val(username);
                    $('iframe').contents().find('.wysihtml5-editor').html(user_message);
                    $('#user_message_edit').val(user_message);
                    $('iframe').contents().find('.wysihtml5-editor').focus();
                },
                error: function(e){ alert('Error on changing the status'); }
            });
            $('#user_msg_id').val(msg_id);   
        });

        //Click event delete button delete the message from the list.
        $(document.body).on('click', '.btn-msg-delete', function(e) {

            e.preventDefault();
            $this = $(this);
            var btn = $(this);
            var text = $this.attr('data-text') ? $this.attr('data-text') : 'Yes, Delete it!';
            var msg_id = $(this).data('msg_id');

            swal({
                title: 'Are you sure?',
                text: 'Do you want to delete the message',
                type: 'info',
                showCancelButton: true,
                confirmButtonClass: 'btn-danger',
                confirmButtonText: text,
                closeOnConfirm: true
            }, function() {
                var url = '/message/messageaction/';
                $.ajax({
                    type : 'POST',
                    data : {msg_id:msg_id,delete:'delete'},
                    url  : url,
                    success : function(data){ 
                        data = $.parseJSON(data);
                        if(data.status){
                            $('#success-msg-box').removeAttr('style');
                            $('#success-msg').text(data.message);
                        } else {
                            $('#err-msg-box').removeAttr('style');
                            $('#err-msg').text(data.message);
                        }
                        
                        $('.table').bootstrapTable('refresh');
                    },
                    error : function(e){ alert('Error on deleting the message');},
                });
            });
        });

        //Click event of the read button to read the message as read.
        $(document.body).on('click', '.btn-msg-read', function(e) {
            var btn = $(this);
            var read_status = $(this).data('read_status');
            var msg_id = $(this).data('msg_id');
            var url = '/message/messageaction/';
            data = (read_status=='unread')? {msg_id:msg_id,unread:read_status} : {msg_id:msg_id,read:read_status};
            $.ajax({
                type : 'POST',
                data : data,
                url  : url,
                success : function(){ $('.table').bootstrapTable('refresh');},
                error : function(e){ alert('Error on changing the message status');},
            });
        });

        //Click event of the archive button to make the message as read and archived for the client.
        $(document.body).on('click', '.btn-msg-archive', function(e) {
            var btn = $(this);
            var msg_id = $(this).data('msg_id');
            var url = '/message/messageaction/';
            $.ajax({
                type : 'POST',
                data : {msg_id:msg_id,archive:'archive'},
                url  : url,
                success : function(){ $('.table').bootstrapTable('refresh');},
                error : function(e){ alert('Error on archiving the message status');},
            });
        });

        //Click event of the view button displays the Invoice item in modal box .
        $(document).on('click', '.btn-view', function(e) {
            var btn = $(this);
            var item = $(this).data('invoice_item');

            $('#user_invoice_items_view').val(item);          
        });

        //Click event of the edit button displays the invoice item in modal box for editing.
        $(document).on('click', '.btn-edit', function(e) {
            var btn = $(this);
            var item = $(this).data('edit_invoice_item');
            
            $('#user_invoice_item_edit').val(item);
           
        });

        //Click event delete button delete the invoice item from the list.
        $(document).on('click', '.btn-delete', function(e) {

            e.preventDefault();
            $this = $(this);
            var btn = $(this);
            var text = $this.attr('data-text') ? $this.attr('data-text') : 'Yes, Delete it!';
            var invoice_item_id = $(this).data('invoice_item_id');

            swal({
                title: 'Are you sure?',
                text: 'Do you want to delete the invoice item',
                type: 'info',
                showCancelButton: true,
                confirmButtonClass: 'btn-danger',
                confirmButtonText: text,
                closeOnConfirm: true
            }, function() {
                var url = '/user/invoice_item_action/';
                $.ajax({
                    type : 'POST',
                    data : {invoice_item_id:invoice_item_id,delete:'delete'},
                    url  : url,
                    success : function(){ $('.table').bootstrapTable('refresh');},
                    error : function(e){ alert('Error on deleting the invoice_item');},
                });
            });
        });

        $(document).on('click', '.btn-edit', function(e) {
            var btn = $(this);
            var invoice_item_id = $(this).data('invoice_item_id');

            $('#hidden_invoice_item_id').val(invoice_item_id);          
        });    
    },

    /*
    * table search
    */
    tableSearch: function() {

        // live filter searching
        $('input.filter').on('keyup', function() {
            var $table = $(this).closest('.tab-pane').find('table');
            var rex = new RegExp($(this).val(), 'i');
            $table.find('tbody tr').hide();
            $table.find('tbody tr').filter(function() {
                return rex.test($(this).text());
            }).show();
            if ( $table.find('tbody tr:visible').length === 0 ) {
                $table.find('tbody').next('tfoot').show();
            } else {
                $table.find('tbody').next('tfoot').hide();
            }
        });
        
    },

    /*
     * Add our jquery form validation here
     */
    addValidation: function() {
        jQuery.validator.addMethod("ownedPercent", function(value, element) {
            var total=0;
            $('.owned_percent').each(function() {
                if($(this).val()!=""){
                    total = total+parseInt($(this).val())
                }
            });
            return total<=100?true:false;
        }, "Sum of Owned Percent must be less than or equal to 100%");
        jQuery.validator.addMethod("mobileValidation", function(value, element) {
            var checkMobile=true;
            $('.mobile_profile').each(function() {
                var newmobile = $(this).val();
                var newcleaned = ('' + newmobile).replace(/\D/g, '');
                if(newcleaned.length != 10){
                    checkMobile = false;
                }
            });
            return checkMobile;
        }, "Mobile number must be 10 digits");
        jQuery.validator.addMethod("invoiceValidEither", function(value, element) {
            var emails = $('#invoice_emails').val();
            var phones = $('#invoice_phones').val();
            var full_number =$('input[name="full_number"]').val();
            if($("#invoice_send_to").is(":checked")){
                return !emails && !phones && !full_number?false:true;
            }
            else{
             return true;
            }
          ;
         }, "Email or Phone must be required anyone for sending invoice.");
        $('form.validate').each(function() {
            $(this).validate({
                errorClass: 'validate-error control-label',
                validClass: 'validate-valid control-label',
                ignore: 'select:hidden:not(.selectpicker,.tabs_hidden), input:hidden:not(.tabs_hidden), textarea:hidden',
                errorPlacement: function(error, element) {
                    if ( element.is('input:checkbox') || element.is('input:radio') ) {
                        var lastElement = $('[name="' + element.attr('name') + '"]:last');
                        lastElement = element.parent().hasClass('icheck') ? lastElement.closest('label') : lastElement;
                        error.insertAfter(lastElement.parent().is('label') ? lastElement.parent() : lastElement);
                    } else {
                        if ( element.closest('.input-group').length == 1 ) {
                            error.insertAfter(element.closest('.input-group'));
                        } else {
                            error.insertAfter(element);
                        }
                    }
                },
                highlight: function(element, errorClass, validClass) {
                    if ( $(element).closest('.form-group').length == 1 ) {
                        $(element).closest('.form-group').addClass('has-error');
                    } else {
                        $(element).addClass('validate-error');
                    }
                },
                unhighlight: function(element, errorClass, validClass) {
                    if ( $(element).closest('.form-group').length == 1 ) {
                        $(element).closest('.form-group').removeClass('has-error');
                    } else {
                        $(element).removeClass('validate-error');
                    }
                },
                /*onkeyup: function(element, event) {
                    if ( !$(element).hasClass('check-email') ) {
                        $(element).valid();
                    }
                },*/
                onfocusout: function(element, event) {
                    if ( !$(element).hasClass('check-email') && $(element).attr('aria-invalid') ) {
                        $(element).valid();
                    }
                },
                invalidHandler: function(event, validator) {
                    setTimeout(function() {
                        $('.nav-tabs a span.required').remove();
                        $('.nav-tabs a').removeClass('tab_error');
                        var validatePane = $('.tab-content.tab-validate .tab-pane:has(.has-error)').each(function() {
                            var id = $(this).attr('id');
                            $('.nav-tabs').find('a[href^="#' + id + '"]').addClass('tab_error');
                            $('.nav-tabs').find('a[href^="#' + id + '"]').append(' <span class="required fa fa-exclamation-circle"></span>');
                        });
                    });
                },
                messages: {
                    "ownershipPercent[]": {
                        min: "Percent owned will not allow 0%",
                        max: "Percent owned will not allow 100% and more",
                    }
                },
                submitHandler: function(form) {
                    $(".validate").find("input[type='submit']").val('Please Wait ..');
                    $(".validate").find("input[type='submit']").attr('disabled','true');

                    $(".validate").find("button[type='submit']").val('Please Wait ..');
                    $(".validate").find("button[type='submit']").attr('disabled','true');
                    app.submitForm(form);
                }
            });
        });

    },

    showSuccess: function() {
        swal({
            title: 'Thank You!',
            text: app.response,
            type: 'success',
            showCancelButton: true,
            confirmButtonClass: 'btn-primary',
            confirmButtonText: 'Make Another Payment',
            cancelButtonText: 'Close',
            closeOnConfirm: false,
            closeOnCancel: true
        },
        function(isConfirm) {
            if ( isConfirm ) {
                url = document.URL.replace(/(\?|#).*/, '');
                window.location = url;
            }
        });
    },

    showError: function() {
        swal({
            title: 'Oh Snap!',
            text: app.response,
            type: 'error',
            showCancelButton: false,
            confirmButtonClass: 'btn-default',
            confirmButtonText: 'Close',
            cancelButtonText: 'Close',
            closeOnConfirm: true,
            closeOnCancel: true
        });
    },

    getCardType: function(number) {
        var re = new RegExp('^4[0-9]');
        if (number.match(re) != null) {
            return 'visa';
        }
        re = new RegExp('^3[47][0-9]');
        if (number.match(re) != null) {
            return 'amex';
        }
        re = new RegExp('^5[1-5][0-9]');
        if (number.match(re) != null) {
            return 'mastercard';
        }
        re = new RegExp('^6(?:011|5[0-9]{2})[0-9]');
        if (number.match(re) != null) {
            return 'discover';
        }
        return 'none';
    },

    response: ''


};


/*
 * launch everything on document ready
 */
$(function() {
    app.init();
});
